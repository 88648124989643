import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";
import SingleItem from "./SingleItem";
import CustomPagination from "../../../../../CustomPagination/CustomPagination";
import { Offcanvas } from "react-bootstrap";
import LoanHistoryDetails from "./LoanHistoryDetails";

const LoanHistory = () => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/loan/history/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/loan/history/view/${authUser?._id}/?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const [OderId, setOrderId] = useState();
  const [ModalShow, setModalhow] = useState(false);
  const handleClose = () => setModalhow(false);

  const HandleModalShow = (id) => {
    setOrderId(id);
    setModalhow(true);
  };

  if (loading) {
    return;
  }
  return (
    <>
      <section className="mobile-history-item-list">
        {data?.length !== 0 ? (
          data.map((data, index) => {
            if (data) {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  key={data?._id}
                  HandleModalShow={HandleModalShow}
                  paginateData={paginateData}
                ></SingleItem>
              );
            }
          })
        ) : (
          <div className="text-center pt-5">
            <p className="text-write">No records available</p>
          </div>
        )}

        {/* paginateLinks */}
        <CustomPagination
          data={paginateData}
          handlePage={handlePage}
        ></CustomPagination>
        {/* paginateLinks */}
      </section>

      <Offcanvas
        show={ModalShow}
        placement="bottom"
        className="user-history-modal-area"
      >
        <LoanHistoryDetails OderId={OderId} handleClose={handleClose} />
      </Offcanvas>
    </>
  );
};

export default LoanHistory;
