import React, { useContext, useEffect, useState } from "react";
import "./Orders.css";
import Pending from "./Pending/Pending";
import History from "./History/History";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import Position from "./Position/Position";

const Orders = () => {
  
  const [HistoryShow, setHistoryShow] = useState("Position");
  const { authUser } = useContext(AuthContext);


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  const fetchData = async () => {
    if (authUser?._id) {
      const data = await fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/running/history/${authUser?._id}`
      );
      const apiResponse = await data.json();
      setData(apiResponse);
      if (apiResponse) {
        setLoading(false);
      }
    }
  };


  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`${process.env.REACT_APP_API}/api/user/auth/view/single/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id]);

  if (loading) {
    return;
  }

  return (
    <>
      <section className="orders-area">
        <div className="container">
          <div className="orders-menu-area">
            <div className="d-flex justify-content-between align-items-center">
              <h5
                className={HistoryShow === "Position" ? "active" : ""}
                onClick={() => setHistoryShow("Position")}
              >
                Position holding
              </h5>
              <h5
                className={HistoryShow === "Pending" ? "active" : ""}
                onClick={() => setHistoryShow("Pending")}
              >
                Pending Orders
              </h5>
              <h5
                className={HistoryShow === "History" ? "active" : ""}
                onClick={() => setHistoryShow("History")}
              >
                History
              </h5>
            </div>
          </div>

          <div className="orders-profit-loss">
            <h5>Profit and Loss</h5>
            <h3 className={data?.ProfitandLoss < 0 ? "text-danger-1" : ""}>
              {!(isNaN(data?.ProfitandLoss))  &&  parseFloat(data?.ProfitandLoss).toFixed(2)}
            </h3>
          </div>
          <div className="user-orders-balance-area">
            <div className="d-flex justify-content-between align-items-center pb-2">
              <p>Balance</p>
              <span>
                { !(isNaN(userData?.balance))  &&  (
                  parseFloat(data?.ProfitandLoss) + parseFloat(userData?.balance)
                ).toFixed(2)}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-2">
          <p>Current Margin</p>
          <span>{data?.TotalMargin}</span>
        </div>
          </div>

          {HistoryShow === "Position" && <Position PositionHoldingData={data}  loading={loading} />}
          {HistoryShow === "Pending" && <Pending />}
          {HistoryShow === "History" && <History />}
        </div>
      </section>
    </>
  );
};

export default Orders;
