import React, { useState } from "react";
import "./HistroyPage.css";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DepositHistory from "./DepositHistory/DepositHistory";
import WithdrawalHistory from "./WithdrawalHistory/WithdrawalHistory";
import LoanHistory from "./LoanHistory/LoanHistory";
import TradeHistory from "./TradeHistory/TradeHistory";
import BitcoinMiningHistory from "./BitcoinMiningHistory/BitcoinMiningHistory";
import GoldMiningHistory from "./GoldMiningHistory/GoldMiningHistory";
import InvestmentPlanHistory from "./InvestmentPlanHistory/InvestmentPlanHistory";
const HistoryPage = () => {
  const [HistoryItem, setHistoryItem] = useState("Deposit");

  return (
    <>
      <section className="container">
        <div className="mining-area">
          <div className="user-form-back">
            <Link to="/user/menu">
              <FontAwesomeIcon icon={faChevronLeft} className="text-start" />
            </Link>
            <h6>History</h6>
          </div>
        </div>
        <div className="user-history-area">
          <section className="markets-area">
            <div className="container">
              <div className="user-history-menu-area">
                <div className="d-flex justify-content-between align-items-center">
                  <h5
                    onClick={() => setHistoryItem("Deposit")}
                    className={HistoryItem === "Deposit" ? "active" : ""}
                  >
                    Deposit
                  </h5>
                  <h5
                    onClick={() => setHistoryItem("Withdrawal")}
                    className={HistoryItem === "Withdrawal" ? "active" : ""}
                  >
                    Withdrawal
                  </h5>
                  <h5
                    onClick={() => setHistoryItem("Trade")}
                    className={HistoryItem === "Trade" ? "active" : ""}
                  >
                    Trade
                  </h5>
                  <h5
                    onClick={() => setHistoryItem("Loan")}
                    className={HistoryItem === "Loan" ? "active" : ""}
                  >
                    Loan
                  </h5>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5
                    onClick={() => setHistoryItem("Bitcoin")}
                    className={HistoryItem === "Bitcoin" ? "active" : ""}
                  >
                    Bitcoin Mining
                  </h5>
                  <h5
                    onClick={() => setHistoryItem("Gold")}
                    className={HistoryItem === "Gold" ? "active" : ""}
                  >
                    Gold Mining
                  </h5>
                  <h5
                    onClick={() => setHistoryItem("Investment")}
                    className={HistoryItem === "Investment" ? "active" : ""}
                  >
                    Investment Plan
                  </h5>
                </div>
              </div>

              <div className="mobile-history-item-area">
                {HistoryItem === "Deposit" && <DepositHistory />}
                {HistoryItem === "Withdrawal" && <WithdrawalHistory />}
                {HistoryItem === "Loan" && <LoanHistory />}
                {HistoryItem === "Trade" && <TradeHistory />}
                {HistoryItem === "Bitcoin" && <BitcoinMiningHistory />}
                {HistoryItem === "Gold" && <GoldMiningHistory />}
                {HistoryItem === "Investment" && <InvestmentPlanHistory />}
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default HistoryPage;
