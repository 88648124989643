import React, { useContext, useEffect, useState } from "react";
import { faChevronLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./Assets.css";
import Overview from "./Overview/Overview";
import Currency from "./Currency/Currency";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
const Assets = () => {
  const [userBalanceHideShow, setUserBalanceHideShow] = useState(false);

  const [ItemShow, setItemShow] = useState("Overview");

  const handleItemShow = (data) => {
    setItemShow(data);
  };

  const { authUser } = useContext(AuthContext);

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/auth/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);
if(loading){
  return;
}
  return (
    <>
      <div className="user-form-section">
        <div className="container">
          <div className="user-form-back">
            <Link to="/user/menu">
              <FontAwesomeIcon icon={faChevronLeft} className="text-start" />
            </Link>
            <h6>Assets</h6>
          </div>
          <div className="assets-section-area">
            <div className="assets-menu-area">
              <p
                className={ItemShow === "Overview" ? "active" : ""}
                onClick={() => handleItemShow("Overview")}
              >
                Overview
              </p>
              <p
                className={ItemShow === "Currency" ? "active" : ""}
                onClick={() => handleItemShow("Currency")}
              >
                Currency
              </p>
              <p
                className={ItemShow === "Commodities" ? "active" : ""}
                onClick={() => handleItemShow("Commodities")}
              >
                Commodities
              </p>
              <p
                className={ItemShow === "CFDs" ? "active" : ""}
                onClick={() => handleItemShow("CFDs")}
              >
                CFDs
              </p>
              <p
                className={ItemShow === "Forex" ? "active" : ""}
                onClick={() => handleItemShow("Forex")}
              >
                Forex
              </p>
              <p
                className={ItemShow === "Stocks" ? "active" : ""}
                onClick={() => handleItemShow("Stocks")}
              >
                Stocks
              </p>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-7">
                    <div className="assets-user-balance-area">
                      <p>
                        Avaliable Asset (USDT)&nbsp;
                        <FontAwesomeIcon
                          icon={faEye}
                          className={userBalanceHideShow ? "active" : ""}
                          onClick={() =>
                            setUserBalanceHideShow(!userBalanceHideShow)
                          }
                        />
                      </p>
                      <span>
                        {userBalanceHideShow
                          ? "$00.0000"
                          : `$${
                              !isNaN(userData?.balance) &&
                              parseFloat(userData?.balance).toFixed(2)
                            }`}
                      </span>
                      <small>
                        ≈
                        {userBalanceHideShow
                          ? "$00.0000"
                          : `$${
                              !isNaN(userData?.balance) &&
                              parseFloat(userData?.balance).toFixed(2)
                            }`}
                      </small>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="user-credit-score">
                      <p>Credit Score:&nbsp;100</p>
                    </div>
                  </div>
                </div>

                <div className="assets-btn-area">
                  <Link to="/assets/deposit">Deposit</Link>
                  <Link to="/assets/withdraw">Withdraw</Link>
                  <Link>Transfer</Link>
                </div>
              </div>
            </div>
            {ItemShow === "Overview" && <Overview />}
            {ItemShow === "Currency" && <Currency />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Assets;
