import React, { useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import axios from "axios";
import SingleMessageItem from "./SingleMessageItem";
import "./LiveSupport.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
const ENDPOINT = `${process.env.REACT_APP_API}`; //
let socket;

const LiveSupport = () => {
  const { authUser } = useContext(AuthContext);
  const [ConversationData, setConversationData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const startMessageRef = useRef();
  const userInfoRef = useRef();

  const [UserInfo, setUserInfo] = useState(null);
  const [MessageUserInfo, setMessageUserInfo] = useState([]);

  socket = io(ENDPOINT);

  useEffect(() => {
    socket.on("recvice_message", (data) => {
      setMessages((oldMessage) => [...oldMessage, data]);
    });
  });

  useEffect(() => {
    if (UserInfo) {
      fetch(
        `${process.env.REACT_APP_API}/api/chat/messgae/view/${UserInfo?.user_email}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMessages(data.data);
          setConversationData(data?.ConversationData);
        });
    }
  }, [UserInfo]);


  const typingHandler = (e) => {
    setNewMessage(e.target.value);
  };

  const [userFile, setUserFile] = useState("");

  const handleFile = (e) => {
    setUserFile(e.target.files[0]);
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    try {
      if (UserInfo?.user_email) {
        const storeData = { message: newMessage, attach: userFile };

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        axios
          .post(
            `${process.env.REACT_APP_API}/api/chat/messgae/send/${UserInfo?.user_email}`,
            storeData,
            config
          )
          .then(async (data) => {
            event.target.reset();
            setUserFile("");
            setNewMessage('');
            const NewMessages = data?.data?.data;
            if (NewMessages) {
              await socket.emit("send_message", NewMessages);
              setMessages([...messages, NewMessages]);
            }
          })
          .catch((error) => {});
      }
    } catch (error) {}
  };

  const StartMessage = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    try {
      if (authUser?.email) {
        const storeData = {
          fname: authUser?.fname,
          lname: authUser?.lname,
          email: authUser?.email,
        };
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .post(
            `${process.env.REACT_APP_API}/api/chat/messgae/send/${authUser?.email}`,

            storeData,
            config
          )
          .then(async (data) => {
            event.target.reset();

            const results = data?.data?.ConversationData;
            if (UserInfo === null) {
              localStorage.setItem(
                "robicon_live_message",
                JSON.stringify(results)
              );
            } else {
              localStorage.removeItem("robicon_live_message");
              setMessageUserInfo(null);
              localStorage.setItem(
                "robicon_live_message",
                JSON.stringify(results)
              );
            }
            socket = io(ENDPOINT);
            socket.emit("join_room", results?.user_email);
            setMessageUserInfo(results);
            startMessageRef.current.style.display = "block";
            userInfoRef.current.style.display = "none";
          })
          .catch((error) => {});
      } else {
        const storeData = {
          fname: InpuData?.fname,
          lname: InpuData?.lname,
          email: InpuData?.email,
        };
        if (
          storeData?.fname &&
          storeData?.lname &&
          storeData?.email !== "" &&
          storeData?.email
        ) {
          const config = {
            headers: {
              "content-type": "application/json",
            },
          };
          axios
            .post(
              `${process.env.REACT_APP_API}/api/chat/messgae/send/${storeData?.email}`,
              storeData,
              config
            )
            .then(async (data) => {
              event.target.reset();
              const results = data?.data?.ConversationData;
              if (UserInfo === null) {
                localStorage.setItem(
                  "robicon_live_message",
                  JSON.stringify(results)
                );
              } else {
                localStorage.removeItem("robicon_live_message");
                setMessageUserInfo(null);
                localStorage.setItem(
                  "robicon_live_message",
                  JSON.stringify(results)
                );
              }
              socket = io(ENDPOINT);
              socket.emit("join_room", results?.user_email);
              setMessageUserInfo(results);
              startMessageRef.current.style.display = "block";
              userInfoRef.current.style.display = "none";
            })
            .catch((error) => {});
        } else {
          toast.error(`all input filed is required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem("robicon_live_message")));
  }, [MessageUserInfo]);

  return (
    <>
      <div className="chat-section">
        <section className="chat-section-area">
          <div className="row">
            <div className="col-md-12 shadow-lg">
              <div
                className="box box-success direct-chat direct-chat-success"
                style={{ display: "none" }}
                ref={startMessageRef}
              >
                <div className="box-header with-border">
                  <h3 className="box-title">Live Chat</h3>
                </div>
                <div className="box-body">
                  <div className="direct-chat-messages">
                    {messages?.length !== 0 ? (
                      messages
                        .reduce((finalArray, current) => {
                          let obj = finalArray.find(
                            (item) => item?._id === current?._id
                          );
                          if (obj) {
                            return finalArray;
                          }
                          return finalArray.concat([current]);
                        }, [])
                        .map((data, index) => {
                          if (data) {
                            return (
                              <SingleMessageItem
                                data={data}
                                key={data?._id}
                                index={index}
                                ConversationData={ConversationData}
                              ></SingleMessageItem>
                            );
                          }
                        })
                    ) : (
                      <div className="text-center mt-3">Message not found</div>
                    )}
                  </div>
                </div>

                <div className="box-footer">
                  <form onSubmit={sendMessage}>
                    <div className="input-group">
                      <input
                        type="text"
                        name="message"
                        placeholder="Type Message ..."
                        className="form-control"
                        onChange={typingHandler}
                      />
                      <span className="input-group-btn d-flex">
                        <div className="chat-file-upload mx-1">
                          <label class="filelabel">
                            <FontAwesomeIcon icon={faPaperclip} />
                            <input
                              class="FileUpload1"
                              id="FileInput"
                              onChange={handleFile}
                              type="file"
                              accept=".jpg, .png, .jpeg, .JPG, .PNG, .JPEG"
                            />
                          </label>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-success btn-flat chat-file-btn"
                        >
                          {/* Send */}
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              <form onSubmit={StartMessage}>
                <div
                  className="box box-success direct-chat direct-chat-success"
                  ref={userInfoRef}
                >
                  <div className="box-header with-border">
                    <h3 className="box-title">Live Chat</h3>
                    <div className="box-tools pull-right">
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse"
                      >
                        <i className="fa-solid fa-file-image"></i>
                      </button>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="py-3">
                      {authUser ? (
                        <>
                          <div className="container">
                            <div className="mb-1">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                name="fname"
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                                defaultValue={authUser?.fname}
                                disabled
                              />
                            </div>
                            <div className="mb-1">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                name="lname"
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                                defaultValue={authUser?.lname}
                                disabled
                              />
                            </div>
                            <div className="mb-1">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                                defaultValue={authUser?.email}
                                disabled
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="container">
                            <div className="mb-1">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                name="fname"
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                              />
                            </div>
                            <div className="mb-1">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                name="lname"
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                              />
                            </div>
                            <div className="mb-1">
                              <label
                                htmlFor="exampleFormControlInput2"
                                className="form-label"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="exampleFormControlInput2"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="box-footer">
                    <div className="d-grid gap-2">
                      <button
                        type="submit"
                        className="btn btn-success btn-flat"
                      >
                        Start Chat
                        <i className="fa-regular fa-paper-plane ms-2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default LiveSupport;
