import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ListsSingleItem = ({data}) => {
  return (
    <>
      <div className="news-lists-item">
      <LazyLoadImage
              alt="img"
              effect="blur"
              src={`${process.env.REACT_APP_API}/${data?.image}`}
            />
        
        <div className="news-content">
          <h4>
           {(data?.title).slice(0, 70)} {(data?.title).length >= 70 && "....."}
          </h4>
          <p>
          {(data?.dis).slice(0, 150)}
          </p>
          <span>Posted On  {dateFormat(data?.createdAt, "mmmm dd, yyyy")}</span>
          <Link to={`/user/news/details/${data?._id}`}>Read More</Link>
        </div>
      </div>
    </>
  );
}; 

export default ListsSingleItem;
