import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
const LoanHistoryDetails = ({ OderId, handleClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/loan/single/history/view/${OderId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setData(data?.data);
          setLoading(false);
        }
      });
  }, []);

  if (loading) {
    return;
  }
  return (
    <>
      <div className="container">
        <div className="user-history-modal-header">
          <h5>Details</h5>
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} />
        </div>
        <div className="user-history-details-area">
          <div className="user-history-items">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>Plan: {data?.loan_package_name}</p>
                <p>Amount: ${data?.amount}</p>
                <p>Installment&nbsp;Amount: ${data?.total_amount}</p>
                <p>Installment: 1</p>
                <p>Next&nbsp;Installment: {data?.expired_time}</p>
                <p>
                  Paid:
                  {data?.status === 1 && (
                    <span className="badge bg-warning text-dark ms-2">Unpaid</span>
                  )}
                  {data?.status === 2 && (
                    <span className="badge bg-primary ms-2">Paid</span>
                  )}
                </p>

                <p>
                  Status:
                  {data?.status === 0 && (
                    <span className="badge bg-warning text-dark ms-2">Pending</span>
                  )}
                  {data?.status === 3 && (
                    <span className="badge bg-danger ms-2">Reject</span>
                  )}
                  {data?.status === 1 || data?.status === 2 && (
                    <span className="badge bg-primary ms-2">Approved</span>
                  )}
                </p>
                <p>Date: {dateFormat(data?.createdAt, "dd-mm-yyyy h:MM TT")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanHistoryDetails;
