import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
const HistoryDetailsModal = ({ OderId, handleClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/trade/log/single/view/${OderId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setData(data?.data);
          setLoading(false);
        }
      });
  }, []);

  if (loading) {
    return;
  }
  return (
    <>
      <div className="container">
        <div className="user-history-modal-header">
          <h5>Order Details</h5>
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} />
        </div>
        <div className="user-history-details-area">
          <h2>{data?.pairs}</h2>
          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="orders-item-details">
              <p>
                {data?.lower_unit_price} -&gt;
                <span>{data?.current_price}</span>
              </p>
            </div>
            <div
              className={
                data?.direction === "Buy"
                  ? "orders-item-up-value"
                  : "orders-item-dwon-value"
              }
            >
              <h6>
                <p>{data?.direction === "Buy" ? "Buy" : "Sell"}</p>
                <span>{data?.lots} Lots</span>
              </h6>
            </div>
          </div>
          <div className="user-history-items">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>Set loss: {data?.set_loss}</p>
                <p>Take profit: {data?.take_profit}</p>
                <p>Margin: {data?.margin}</p>
                <p>Handling fee: 0</p>
                <p>Orders ID {data?.transaction}</p>
                <p> {dateFormat(data?.createdAt, "dd-mm-yyyy h:MM TT")}</p>
              </div>
              <div className="orders-history-item">
                <div
                  className={
                    data?.direction === "Buy"
                      ? "orders-item-up-value"
                      : "orders-item-dwon-value"
                  }
                >
                  <h5
                    className={
                      parseFloat(data?.profit) < 0 ? "text-danger-1" : ""
                    }
                  >
                    {parseFloat(data?.profit).toFixed(2)}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryDetailsModal;
