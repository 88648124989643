import React from "react";
import dateFormat from "dateformat";
const SingleItem = ({ data, HandleModalShow, index, paginateData }) => {
  return (
    <>
      <div
        className="mobile-history-item"
        onClick={() => HandleModalShow(data?._id)}
      >
        <div className="mobile-history-item-left">
          <div className="mobile-history-item-sn">
            <p>S.N</p>
            <span>
              #
              {parseFloat(index + 1) +
                parseFloat(paginateData?.page) *
                  parseFloat(paginateData?.limit) -
                10}
            </span>
          </div>
          <div>
            <p>{data?.pairs}</p>
            <span>{data?.transaction}</span>
          </div>
        </div>

        <div className="mobile-history-item-right">
          <p className={parseFloat(data?.profit) < 0 ? "text-danger-1" : ''}>${parseFloat(data?.profit).toFixed(2)}</p>
          <span>
            {dateFormat(data?.createdAt, "mmm,")}&nbsp;
            {dateFormat(data?.createdAt, "dS")}&nbsp;
            {dateFormat(data?.createdAt, "yyyy")}
          </span>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
