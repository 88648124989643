import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import Swal from 'sweetalert2';
const CommoditiesTrade = ({ TradeData, getLivePrice, marketStatusRef }) => {
  const { authUser } = useContext(AuthContext);
  const { MarketData, marketSymbol } = TradeData;
  const livePrice = getLivePrice?.current;

  //-------------------------------   form data area -----------------------------------------------/////

  const [pendingPrice, setPendingPrice] = useState(false);
  const handlePendingPrice = (data) => {
    switch (data) {
      case "market":
        setPendingPrice(false);
        break;
      case "pending":
        setPendingPrice(true);
        break;
      default:
        break;
    }
  };
  //--------- Set Loss -------------//

  const [LossDis, setLossDis] = useState(true);
  const [LossValue, setLossVale] = useState();
  const handleLossDis = (data) => {
    if (data) {
      !LossValue && setLossVale(livePrice);
      setLossDis(false);
    } else {
      setLossDis(true);
    }
  };

  const handleSetLoss = (e) => {
    if (e === "-") {
      const minusValue = parseFloat(parseFloat(LossValue) - 1);
      setLossVale(minusValue);
    } else if (e === "+") {
      const plusValue = parseFloat(parseFloat(LossValue) + 1);
      setLossVale(plusValue);
    } else {
      const value = e.target.value;
      setLossVale(parseFloat(value));
    }
  };

  //--------- Take Profit  -------------//

  const [takeProfitDis, setTakeProfitDis] = useState(true);
  const [takeProfitValue, setTakeProfitValue] = useState();
  const handleTakeProfitDis = (data) => {
    if (data) {
      !takeProfitValue && setTakeProfitValue(livePrice);
      setTakeProfitDis(false);
    } else {
      setTakeProfitDis(true);
    }
  };

  const handleTakeProfit = (e) => {
    if (e === "-") {
      const minusValue = parseFloat(parseFloat(takeProfitValue) - 1);
      setTakeProfitValue(minusValue);
    } else if (e === "+") {
      const plusValue = parseFloat(parseFloat(takeProfitValue) + 1);
      setTakeProfitValue(plusValue);
    } else {
      const value = e.target.value;
      setTakeProfitValue(parseFloat(value));
    }
  };

  //--------- Lots  -------------//

  const [lostsValue, setLostsValue] = useState(0.01);
  const handleLots = (e) => {
    if (e === "-") {
      const minusValue = parseFloat((parseFloat(lostsValue) - 0.01).toFixed(2));
      setLostsValue(minusValue);
    } else if (e === "+") {
      const plusValue = parseFloat((parseFloat(lostsValue) + 0.01).toFixed(2));
      setLostsValue(plusValue);
    } else {
      const value = e.target.value;
      setLostsValue(parseFloat(value));
    }
  };

  //----------------- Margin ----------------///

  const [Margin, setMargin] = useState(0);
  const [Multiplier, setMultiplier] = useState(1);

  useEffect(() => {
    if (livePrice) {
      setMargin(
        parseFloat(
          parseFloat(
            parseFloat(livePrice.toFixed(3)) * parseFloat(lostsValue)
          ) / parseFloat(Multiplier)
        ).toFixed(5)
      );
    }
  }, [livePrice, Multiplier, lostsValue]);

  const [PendingPriceValue, setPendingPriceValue] = useState();

  // store data
  const refSubmitDisBuy = useRef();
  const refSubmitDisSell = useRef();

  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = (Direction) => {
    if (livePrice > 0) {
      const storeData = {
        user: authUser,
        pairs: TradeData?.marketSymbol,
        crypto_id: MarketData?._id,
        direction: Direction,
        lots: lostsValue,
        def_price: MarketData?.price,
        lower_unit_price: livePrice,
        take_profit: takeProfitValue,
        set_loss: LossValue,
        margin: Margin,
        multiplier: Multiplier,
        pending_order: PendingPriceValue,
        pendingPrice: pendingPrice,
        LossDis: LossDis,
        takeProfitDis: takeProfitDis,
        market: "Commodities",
      };
      setErrorMessage();
      refSubmitDisBuy.current.setAttribute("disabled", true);
      refSubmitDisSell.current.setAttribute("disabled", true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API}/api/user/trade/log/store`,
          storeData,
          config
        )
        .then((data) => {
          if (data?.data?.success === true) {
            toast.success(`${data.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDisBuy.current.removeAttribute("disabled");
            refSubmitDisSell.current.removeAttribute("disabled");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${data.data.message}`,
              customClass: {
                popup: 'sweetalert2-body',
                title:"sweetalert2-title",
                htmlContainer:"sweetalert2-htmlContainer",
                confirmButton:"bg-primary"
              }
            });
            refSubmitDisBuy.current.removeAttribute("disabled");
            refSubmitDisSell.current.removeAttribute("disabled");
          }
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.errors);
          if (
            error?.response?.data?.success === false &&
            error?.response?.data?.message
          ) {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          refSubmitDisBuy.current.removeAttribute("disabled");
          refSubmitDisSell.current.removeAttribute("disabled");
        });
    } else {
      toast.error(`Something is wrong try again`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/auth/view/single/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id]);

  return (
    <>
      <section className="user-trade-page">
        <div className=" rounded-10  box-border ">
          <div className="user-pending-order-btn-area">
            <div
              className={!pendingPrice ? "active" : ""}
              onClick={() => handlePendingPrice("market")}
            >
              Market Price
            </div>
            <div
              className={pendingPrice ? "active" : ""}
              onClick={() => handlePendingPrice("pending")}
            >
              Pending Orders
            </div>
          </div>
          {pendingPrice && (
            <div className="el-select w-full mt-4">
              <div className="form-group">
                <label
                  htmlFor="exampleFormControlInput2"
                  className="form-label"
                >
                  Price
                </label>
                <input
                  type="text"
                  name="price"
                  className="form-control"
                  id="exampleFormControlInput2"
                  aria-describedby="emailHelp"
                  placeholder="Please enter the price"
                  onChange={(e) => setPendingPriceValue(e.target.value)}
                />
              </div>
              {errorMessage?.pending_order && (
                <span className="text-danger pt-2">
                  {errorMessage?.pending_order?.msg}
                </span>
              )}
            </div>
          )}

          <div className="el-select w-full mt-4">
            <div className="text-14 mt-10">Multiplier</div>
            <select
              className="form-select"
              onChange={(e) => setMultiplier(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="1">
                100
              </option>
              <option value="2">200</option>
              <option value="3">300</option>
              <option value="4">400</option>
              <option value="5">500</option>
            </select>
          </div>
          <div className="el-select w-full mt-4">
            <div className="text-14 mt-10 trade-dis-btn">
              Set Loss
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  onChange={(e) => handleLossDis(e.target.checked)}
                />
              </div>
            </div>
            <div className="trade-quantity">
              <button
                className="btn "
                id="minusBtn"
                onClick={() => handleSetLoss("-")}
                disabled={LossValue <= 0 ? true : false || LossDis}
              >
                -
              </button>
              <input
                type="number"
                id="quantityInput"
                className="form-control "
                value={LossValue}
                disabled={LossDis}
                onChange={handleSetLoss}
              />

              <button
                className="btn "
                id="plusBtn"
                onClick={() => handleSetLoss("+")}
                disabled={LossDis}
              >
                +
              </button>
            </div>
            {errorMessage?.set_loss && (
              <span className="text-danger pt-2">
                {errorMessage?.set_loss?.msg}
              </span>
            )}
          </div>
          <div className="el-select w-full mt-4">
            <div className="text-14 mt-10 trade-dis-btn">
              Take Profit
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  onChange={(e) => handleTakeProfitDis(e.target.checked)}
                />
              </div>
            </div>
            <div className="trade-quantity">
              <button
                className="btn "
                id="minusBtn"
                onClick={() => handleTakeProfit("-")}
                disabled={takeProfitValue <= 0 ? true : false || takeProfitDis}
              >
                -
              </button>
              <input
                type="number"
                id="quantityInput"
                className="form-control "
                value={takeProfitValue}
                disabled={takeProfitDis}
                onChange={handleTakeProfit}
              />

              <button
                className="btn "
                id="plusBtn"
                onClick={() => handleTakeProfit("+")}
                disabled={takeProfitDis}
              >
                +
              </button>
            </div>
            {errorMessage?.take_profit && (
              <span className="text-danger pt-2">
                {errorMessage?.take_profit?.msg}
              </span>
            )}
          </div>
          <div className="el-select w-full mt-4">
            <div className="text-14 mt-10 trade-dis-btn">Lots(Step:0.01)</div>
            <div className="trade-quantity">
              <button
                className="btn "
                id="minusBtn"
                onClick={() => handleLots("-")}
                disabled={lostsValue === 0.01 ? true : false}
              >
                -
              </button>
              <input
                type="number"
                id="quantityInput"
                className="form-control "
                value={lostsValue}
                onChange={handleLots}
                name="losts"
              />

              <button
                className="btn "
                id="plusBtn"
                onClick={() => handleLots("+")}
              >
                +
              </button>
            </div>
            {errorMessage?.lots && (
              <span className="text-danger pt-2">
                {errorMessage?.lots?.msg}
              </span>
            )}
          </div>

          <div>
            <div className="text-12 py-16">
              <div className="flex items-center justify-between mt-8">
                <div className="trade-page-fs-4">Each Lots</div>
                <div className="trade-page-fs-4">
                  1 Lots = 100 {marketSymbol}
                </div>
              </div>
              <div className="flex items-center justify-between mt-8">
                <div className="trade-page-fs-4">Estimated Handling Fee</div>
                <div className="trade-page-fs-4">0</div>
              </div>
              <div className="flex items-center justify-between mt-8">
                <div className="trade-page-fs-4">Estimated Margin</div>
                <div className="trade-page-fs-4">{Margin}</div>
              </div>
              <div className="flex items-center justify-between mt-8">
                <div className="trade-page-fs-4">Balance</div>
                <div className="trade-page-fs-4">
                  $
                  {!isNaN(userData?.balance) &&
                    parseFloat(userData?.balance).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-2">
              {marketStatusRef.current ? (
                <>
                  <button className="buy-btn" aria-disabled="false" disabled>
                    Buy
                  </button>
                  <button className="sell-btn" aria-disabled="false" disabled>
                    Sell
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="buy-btn"
                    aria-disabled="false"
                    type="submit"
                    onClick={() => handleSubmitData("Buy")}
                    ref={refSubmitDisBuy}
                  >
                    Buy
                  </button>
                  <button
                    className="sell-btn"
                    aria-disabled="false"
                    type="submit"
                    onClick={() => handleSubmitData("Sell")}
                    ref={refSubmitDisSell}
                  >
                    Sell
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommoditiesTrade;
