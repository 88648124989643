import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Forex = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/trade/market/forex/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);


 

  if (loading) {
    return;
  }

  return (
    <>
      {data?.length !== 0 ? (
        data.map((data, index) => {
          if (data) {
            return (
              <SingleItem
                data={data}
                index={index}
                key={data._id}
              ></SingleItem>
            );
          }
        })
      ) : (
        <>
          <div className="text-center h-50">
            <h6> Data not found</h6>
          </div>
        </>
      )}
    </>
  );
};

export default Forex;
