import React, { useContext, useEffect, useState } from "react";
import "./BillingDetails.css";
import axios from "axios";
import SingleItems from "./SingleItems";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import CustomPagination from "../../../../CustomPagination/CustomPagination";
import { faCopy, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
const BillingDetails = () => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [UserData, setUserData] = useState([]);
  const [FreezeData, setFreezeData] = useState([]);
  const [RecordData, setRecordData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/transaction/billing/details/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setUserData(data?.userData);
        setFreezeData(data?.FreezeBalance);
        setRecordData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/transaction/billing/details/view/${authUser?._id}/?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setRecordData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return;
  }
  return (
    <>
      <div className="user-form-section">
        <div className="container">
          <div className="user-form-back">
            <Link to="/user/menu">
              {" "}
              <FontAwesomeIcon icon={faChevronLeft} className="text-start" />
            </Link>
            <h6>Billing Details</h6>
          </div>
          <div className="card">
            <div className="card-body">
              <section className="billing-details-area">
                <div className="container">
                  <h4>USD</h4>
                  <div className="balance-area">
                    <div className="user-balance-area">
                      <div>
                        <p>Available</p>
                        <span>{parseFloat(UserData?.balance).toFixed(2)}</span>
                      </div>
                      <div className="user-freeze-balance-area">
                        <p>Freeze</p>
                        <span>{FreezeData}</span>
                      </div>
                    </div>
                  </div>
                  <div className="records-area">
                    <h6>Financial Records</h6>
                    <div className="records-items-lists">
                      {RecordData?.length !== 0 ? (
                        RecordData.map((data, index) => {
                          if (data) {
                            return (
                              <SingleItems
                                data={data}
                                index={index}
                                key={data?._id}
                                paginateData={paginateData}
                              ></SingleItems>
                            );
                          }
                        })
                      ) : (
                        <tr>
                          <td className="text-muted text-center" colSpan="100%">
                            No records available
                          </td>
                        </tr>
                      )}

                      {/* paginateLinks */}
                      <CustomPagination
                        data={paginateData}
                        handlePage={handlePage}
                      ></CustomPagination>
                      {/* paginateLinks */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingDetails;
