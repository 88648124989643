import React from "react";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Currency = () => {
  return (
    <>
      <div className="user-asset-currency-area">
        <div className="user-asset-currency-top-area">
          <div>
            <input type="text" id="searchInput" placeholder="Search.." />
            <div id="submitsearch">
              <span>Search</span>
            </div>
          </div>
          <p>Hide Small Assets&nbsp; <FontAwesomeIcon icon={faArrowsRotate}  /></p>
        </div>
        <div className="user-asset-currency-items-area">
        <div className="user-assets-overview-items">
            <div className="image-area">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiFpjxAR28PWfNYx8IsrapO8WMg0iG61GXyg&s" alt="" />
                <small>BTC</small>
            </div>
                <div className="row">
                  <div className="col-6">
                    <p>Available</p>
                    <span>0.00</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>On Orders</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
        <div className="user-assets-overview-items">
            <div className="image-area">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsO7_yVWn62dwN4a6LBYfWQjDP8MFGiwgDMg&s" alt="" />
                <small>USDT</small>
            </div>
                <div className="row">
                  <div className="col-6">
                    <p>Available</p>
                    <span>0.00</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>On Orders</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
        <div className="user-assets-overview-items">
            <div className="image-area">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR05Z9BgFFLs6ztxP7xy13tKB3O8vkemY7fZQ&s" alt="" />
                <small>ETH</small>
            </div>
                <div className="row">
                  <div className="col-6">
                    <p>Available</p>
                    <span>0.00</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>On Orders</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </>
  );
};

export default Currency;
