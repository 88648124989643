import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


const SingleItem = ({ data }) => {
  const [LiveData, setLiveData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [LiveData]);
 
  const fetchData = async () => {
    fetch(
      `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:${data?.Symbol}${data?.currency}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.ticker) {
          setLiveData(data?.ticker);
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  if (loading) {
    return;
  }

  return (
    <>
    <Link  to='/user/trade' state={{Market: {
            MarketData: data,
            marketSymbol: `${data?.Symbol}${data?.currency}`,
            Market: "Crypto",
          }}}>
    <div className="markets-item">
        <div className="d-flex justify-content-between align-items-center">
          <div className="markets-item-name">
            <LazyLoadImage
              alt=" img"
              src={`${process.env.REACT_APP_API}/${data?.image}`}
            />
            <h6>
              {data?.Symbol}
              {data?.currency}
            </h6>
          </div>
          <div className="markets-value">
            <p>{!isNaN(LiveData?.lastTrade?.p) && LiveData?.lastTrade?.p}</p>

            {LiveData?.todaysChangePerc < 0 ? (
              <span className="text-danger">
                {parseFloat(LiveData?.todaysChangePerc).toFixed(3)}%
                <FontAwesomeIcon icon={faCaretDown} className="text-danger" />
              </span>
            ) : (
              <span>
                +{parseFloat(LiveData?.todaysChangePerc).toFixed(3)}%
                <FontAwesomeIcon icon={faCaretUp} />
              </span>
            )}
          </div>
        </div>
      </div>
    </Link>
     
    </>
  );
};

export default SingleItem;
