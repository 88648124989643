import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";
import { Offcanvas } from "react-bootstrap";
import HistoryDetailsModal from "./HistoryDetailsModal";

const History = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [OderId, setOrderId] = useState();

  const handleOrderDetails = (id) => {
    setOrderId(id);
    setShow(true);
  };

  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/history/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  if (loading) {
    return;
  }
  return (
    <>
      <div className="orders-history">
        {data?.length !== 0 ? (
          data.map((data, index) => {
            if (data) {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleOrderDetails={handleOrderDetails}
                ></SingleItem>
              );
            }
          })
        ) : (
          <>
            <div className="text-center h-50">
              <h6>No records available</h6>
            </div>
          </>
        )}
      </div>

      <Offcanvas
        show={show}
        placement="bottom"
        className="user-history-modal-area"
      >
        <HistoryDetailsModal OderId={OderId} handleClose={handleClose} />
      </Offcanvas>
    </>
  );
};

export default History;
