import React, { useState } from "react";
import SingleItem from "./SingleItem";
import { Offcanvas } from "react-bootstrap";
import OrderDetailsModal from "./OrderDetailsModal";

const Position = ({PositionHoldingData}) => {

  const {data} = PositionHoldingData;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const[OderId, setOrderId] = useState();

  const handleOrderDetails = (id)=>{
    setOrderId(id);
    setShow(true);
  }

  return (
    <>
      <div className="orders-history">
        {data?.length !== 0 ? (
          data.map((data, index) => {
            if (data) {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleOrderDetails={handleOrderDetails}
                ></SingleItem>
              );
            }
          })
        ) : (
          <>
            <div className="text-center h-50">
              <h6>No records available</h6>
            </div>
          </>
        )}
      </div>

      <Offcanvas show={show}  placement="bottom" className="user-trade-position-modal  user-history-modal-area">
       <OrderDetailsModal OderId={OderId} handleClose={handleClose} />
      </Offcanvas>

    </>
  );
};

export default Position;
