import React, { useRef, useState } from "react";
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";

const MainMaster = () => {


  const MenuCloseByMenuMobile = () => {
    if (MenuMobile) {
      MenuMobileShowRef.current.style.display = "none";
      setMenuMobile(false);
    }
  };
 
  const subMenuCloseByMenuMobile = () => {
    MenuCloseByMenuMobile();
    MenuCloseByMenuMobile();
    if (subMenuMobile) {
      subMenuMobileShowRef.current.style.display = "none";
      setSubMenuMobile(false);
    }
  };



  const subMenuCloseByMenu = () => {
    MenuCloseByMenuMobile();
    subMenuCloseByMenuMobile();
    if (subMenu) {
      subMenuActiveClass.current.classList.remove("submenu-transition");
      subMenuShowRef.current.style.display = "none";
      setSubMenu(false);
    }
  };

  
  const [MenuMobile, setMenuMobile] = useState(false);
  const MenuMobileShowRef = useRef();

  const handleMenuMobile = () => {
    if (MenuMobile) {
      subMenuCloseByMenuMobile();
      MenuMobileShowRef.current.style.display = "none";
      setMenuMobile(false);
    } else {
      MenuMobileShowRef.current.style.display = "block";
      setMenuMobile(true);
    }
  };

 

  const [subMenuMobile, setSubMenuMobile] = useState(false);
  const subMenuMobileShowRef = useRef();

  const handleSubMenuMobile = () => {
    if (subMenuMobile) {
      subMenuMobileShowRef.current.style.display = "none";
      setSubMenuMobile(false);
    } else {
      subMenuMobileShowRef.current.style.display = "block";
      setSubMenuMobile(true);
    }
  };


  const [subMenu, setSubMenu] = useState(false);
  const subMenuShowRef = useRef();
  const subMenuActiveClass = useRef();

  const handleSubMenu = () => {
    if (subMenu) {
      subMenuActiveClass.current.classList.remove("submenu-transition");
      subMenuShowRef.current.style.display = "none";
      setSubMenu(false);
    } else {
      subMenuActiveClass.current.classList.add("submenu-transition");
      subMenuShowRef.current.style.display = "block";
      setSubMenu(true);
    }
  };

  


  const data = {
    subMenu,
    setSubMenu,
    handleSubMenu,
    subMenuShowRef,
    subMenuActiveClass,
    subMenuCloseByMenu,
    MenuMobile,
    setMenuMobile,
    handleMenuMobile,
    MenuMobileShowRef,
    MenuCloseByMenuMobile,
    subMenuMobile,
    setSubMenuMobile,
    subMenuMobileShowRef,
    handleSubMenuMobile,
    subMenuCloseByMenuMobile,
  };

  return (
    <>
      <Header data={data}></Header>
      <div onClick={subMenuCloseByMenu}>
        <Outlet></Outlet>
      </div>
      <Footer></Footer>
    </>
  );
};

export default MainMaster;
