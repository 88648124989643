import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import { Offcanvas } from "react-bootstrap";
import DepositHistoryDetails from "../../History/DepositHistory/DepositHistoryDetails";

const DepositHistory = () => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/payment/gateways/manual/all/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  const [OderId, setOrderId] = useState();
  const [ModalShow, setModalhow] = useState(false);
  const handleClose = () => setModalhow(false);

  const HandleModalShow = (id) => {
    setOrderId(id);
    setModalhow(true);
  };

  if (loading) {
    return;
  }
  return (
    <>
      <section className="user-latest-history-area">
        <div className="pb-3">
          <div className="mobile-history-section">
            <div className="mobile-history-title">
              <h4>Latest Deposit</h4>
            </div>
            <div className="mobile-history-item-list">
              {data?.length !== 0 ? (
                data.map((data, index) => {
                  if (data) {
                    return (
                      <SingleItem
                        data={data}
                        index={index}
                        key={data?._id}
                        HandleModalShow={HandleModalShow}
                      ></SingleItem>
                    );
                  }
                })
              ) : (
                <div className="text-center py-5">
                  <p className="text-dark">No records available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <Offcanvas
        show={ModalShow}
        placement="bottom"
        className="user-history-modal-area"
      >
        <DepositHistoryDetails OderId={OderId} handleClose={handleClose} />
      </Offcanvas>
    </>
  );
};

export default DepositHistory;
