import React, { useContext, useEffect, useState } from "react";
import "./UserMenu.css";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCircleDollarToSlot,
  faHandHoldingDollar,
  faWallet,
  faChevronRight,
  faLock,
  faUserPen,
  faCoins,
  faMagnifyingGlassDollar,
  faBitcoinSign,
  faLandmark,
  faSackDollar,
  faDatabase,
  faRectangleList
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import LogoutComponent from "../../../../Auth/Logout/LogoutComponent";
const UserMenu = () => {
  const { authUser } = useContext(AuthContext);

  const [userBalanceShow, setUserBalanceShow] = useState(true);
  const handleBalanceShow = () =>
    userBalanceShow ? setUserBalanceShow(false) : setUserBalanceShow(true);

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`${process.env.REACT_APP_API}/api/user/auth/view/single/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id]);

  return (
    <>
      <section className="user-menu">
        <div className="user-details">
          <div className="container">
            <h5>{authUser?.email}</h5>
            <p>{authUser?.uid}</p>
            <div className="mt-20 d-flex justify-content-between align-items-center">
              <h4>$ {userBalanceShow ? !(isNaN(userData?.balance)) &&  parseFloat(userData?.balance).toFixed(2) : "*******"}</h4>
              <FontAwesomeIcon icon={faEye} onClick={handleBalanceShow} />
            </div>
            <span>Available Assets</span>
          </div>
        </div>

        <div className="container">
          <div className="user-menu-btn">
            <div className="mt-20 px-10 d-flex justify-content-between align-items-center">
              <Link
                to="/assets/deposit"
                className="user-menu-deposit-btn w-160  h-52 d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faCircleDollarToSlot} />
                <div>
                  <p>Deposit</p>
                  <span> Billing Details&gt;&gt; </span>
                </div>
              </Link>
              <Link
                to="/assets/withdraw"
                className="user-menu-deposit-btn user-menu-Withdrawal-btn w-160  h-52 d-flex align-items-center"
              >
                <FontAwesomeIcon icon={faHandHoldingDollar} />
                <div>
                  <p>Withdrawal</p>
                  <span> Billing Details&gt;&gt; </span>
                </div>
              </Link>
            </div>
          </div>

          <div className="user-menu-items">
         
            <Link
              to="/user/assets"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faMagnifyingGlassDollar} />
                <p>Assets</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/user/billing/details"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faRectangleList} />
                <p>Billing Details</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/bitcoin/mining"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faBitcoinSign} />
                <p>Bitcoin Mining</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/gold/mining"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faCoins} />
                <p>Gold Mining</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/apply/loan"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faLandmark} />
                <p>Apply Loan</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/investment/plan"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faSackDollar} />
                <p>Investment Plan</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/user/history"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faDatabase} />
                <p>History</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/id-auth/kyc"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faWallet} />
                <p>Real name verification</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/password/update"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faLock} />
                <p>Change Password</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <Link
              to="/profile/update"
              className="user-menu-item d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={faUserPen} />
                <p>Change Profile</p>
              </div>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
            <LogoutComponent />
          </div>
        </div>
      </section>
    </>
  );
};

export default UserMenu;
