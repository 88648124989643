import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../Contexts/AuthContext/AuthProvider';
import SingleItem from './SingleItem';
import { Offcanvas } from 'react-bootstrap';
import LoanHistoryDetails from '../../History/LoanHistory/LoanHistoryDetails';

const LoanHistory = () => {
    const { authUser } = useContext(AuthContext);
  const [loanHistory, setLoanHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/loan/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLoanHistory(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  const [OderId, setOrderId] = useState();
  const [ModalShow, setModalhow] = useState(false);
  const handleClose = () => setModalhow(false);

  const HandleModalShow = (id) => {
    setOrderId(id);
    setModalhow(true);
  };

  if (loading) {
    return;
  }
    return (
        <>
           <section className="user-latest-history-area">
        <div className="pb-3">
          <div className="mobile-history-section">
            <div className="mobile-history-title">
              <h4>Latest Loan</h4>
            </div>
            <div className="mobile-history-item-list">
              {loanHistory?.length !== 0 ? (
                loanHistory.map((data, index) => {
                  if (data) {
                    return (
                      <SingleItem
                        data={data}
                        index={index}
                        key={data?._id}
                        HandleModalShow={HandleModalShow}
                      ></SingleItem>
                    );
                  }
                })
              ) : (
                <div className="text-center py-5">
                  <p className="text-dark">No records available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <Offcanvas
        show={ModalShow}
        placement="bottom"
        className="user-history-modal-area"
      >
        <LoanHistoryDetails OderId={OderId} handleClose={handleClose} />
      </Offcanvas>
        </>
    );
};

export default LoanHistory;