import React, { useContext, useEffect, useRef, useState } from "react";
import "./BitcoinMining.css";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../../../../../Contexts/AuthContext/AuthProvider";
import SingleItem from "./SingleItem";
import { faChevronLeft, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import BitcoinMiningHistory from "./BitcoinMiningHistory/BitcoinMiningHistory";
import CustomPagination from "../../../../../CustomPagination/CustomPagination";
import CalculatorData from "../CalculatorData/CalculatorData";
import Swal from 'sweetalert2';
const BitcoinMining = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [MiningaginateData, setMiningaginateData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateData, setUpdateData] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/mining/bitcoin/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setMiningaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState("");

  const handleMiningPage = (event) => {
    try {
      if (event?.target?.value) {
        setFilter(event?.target?.value);
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      if (event?.target?.value) {
        axios
          .get(
            `${process.env.REACT_APP_API}/api/user/mining/bitcoin/filter/view?page=1&filter=${event?.target?.value}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setMiningaginateData(response?.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `${process.env.REACT_APP_API}/api/user/mining/bitcoin/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setMiningaginateData(response?.data);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Store data

  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_API}/api/user/mining/store`,
          { user_id: authUser?._id, ...InpuData },
          config
        )
        .then((data) => {
          if(data?.data?.success){
            setUpdateData(data);
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          event.target.reset();
          }else{
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${data.data.message}`,
              customClass: {
                popup: 'sweetalert2-body',
                title:"sweetalert2-title",
                htmlContainer:"sweetalert2-htmlContainer",
                confirmButton:"bg-primary"
              }
            });
          }
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          setErrorMessage({ id: InpuData?.id, ...error?.response?.data });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  // calcultor

  const [calData, setCalData] = useState([]);
  const [DailyProfit, setDailyProfit] = useState(0.0);
  const [TotalProfit, setTotalProfit] = useState(0.0);
  const [dataValue, setDataValue] = useState({});
  const handleSelect = (e) => {
    if (e.target.value) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/mining/view/${e.target.value}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (dataValue?.amount) {
            if (
              parseFloat(dataValue?.amount) < parseFloat(data?.data?.min_amount)
            ) {
              setDailyProfit(`min:$${data?.data?.min_amount}`);
              setTotalProfit(`min:$${data?.data?.min_amount}`);
              return;
            } else if (
              parseFloat(dataValue?.amount) > parseFloat(data?.data?.max_amount)
            ) {
              setDailyProfit(`max:$${data?.data?.max_amount}`);
              setTotalProfit(`max:$${data?.data?.max_amount}`);
              return;
            }
            if (data?.data?.period_type === "Day") {
              if (data?.data?.principal === 1) {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum =
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100
                  ) / parseFloat(data?.data?.period);
                const TotalProfitsum =
                  parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                  parseFloat(dataValue?.amount);
                setDailyProfit(Dailysum);
                setTotalProfit(TotalProfitsum);
              } else {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum =
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100 -
                      parseFloat(dataValue?.amount)
                  ) / parseFloat(data?.data?.period);
                const TotalProfitsum =
                  parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                  parseFloat(dataValue?.amount);
                setDailyProfit(Dailysum.toFixed(2));
                setTotalProfit(TotalProfitsum.toFixed(2));
              }
            } else if (data?.data?.period_type === "Months") {
              if (data?.data?.principal === 1) {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum = parseFloat(
                  parseFloat(
                    parseFloat(
                      (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                        100
                    ) / parseFloat(parseFloat(data?.data?.period) * 30)
                  )
                );
                const TotalProfitsum = parseFloat(
                  parseFloat(Dailysum) *
                    parseFloat(parseFloat(data?.data?.period) * 30) +
                    parseFloat(dataValue?.amount)
                );
                setDailyProfit(Dailysum);
                setTotalProfit(TotalProfitsum);
              } else {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum = parseFloat(
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100 -
                      parseFloat(dataValue?.amount)
                  ) / parseFloat(parseFloat(data?.data?.period) * 30)
                );
                const TotalProfitsum = parseFloat(
                  parseFloat(Dailysum) *
                    parseFloat(parseFloat(data?.data?.period) * 30) +
                    parseFloat(dataValue?.amount)
                );
                setDailyProfit(Dailysum.toFixed(2));
                setTotalProfit(TotalProfitsum.toFixed(2));
              }
            }

            setCalData(data?.data);
          } else {
            setCalData(data?.data);
          }
        });
    }
  };

  const handleInputBlur = (event) => {
    if (calData?._id) {
      const value = event.target.value;
      const field = event.target.name;
      if (parseFloat(value) < parseFloat(calData?.min_amount)) {
        setDailyProfit(`min:$${calData?.min_amount}`);
        setTotalProfit(`min:$${calData?.min_amount}`);
      } else if (parseFloat(value) > parseFloat(calData?.max_amount)) {
        setDailyProfit(`max:$${calData?.max_amount}`);
        setTotalProfit(`max:$${calData?.max_amount}`);
      } else if (calData?.period_type === "Day") {
        if (calData?.principal === 1) {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat((parseFloat(value) * parseFloat(percentage)) / 100) /
            parseFloat(calData?.period);
          const TotalProfitsum =
            parseFloat(Dailysum) * parseFloat(calData?.period) +
            parseFloat(value);
          setDailyProfit(Dailysum);
          setTotalProfit(TotalProfitsum);
        } else {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat(
              (parseFloat(value) * parseFloat(percentage)) / 100 -
                parseFloat(value)
            ) / parseFloat(calData?.period);
          const TotalProfitsum =
            parseFloat(Dailysum) * parseFloat(calData?.period) +
            parseFloat(value);
          setDailyProfit(Dailysum.toFixed(2));
          setTotalProfit(TotalProfitsum.toFixed(2));
        }
      } else if (calData?.period_type === "Months") {
        if (calData?.principal === 1) {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat((parseFloat(value) * parseFloat(percentage)) / 100) /
            parseFloat(parseFloat(calData?.period) * 30);
          const TotalProfitsum =
            parseFloat(Dailysum) *
              parseFloat(parseFloat(calData?.period) * 30) +
            parseFloat(value);
          setDailyProfit(Dailysum);
          setTotalProfit(TotalProfitsum);
        } else {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat(
              (parseFloat(value) * parseFloat(percentage)) / 100 -
                parseFloat(value)
            ) / parseFloat(parseFloat(calData?.period) * 30);
          const TotalProfitsum =
            parseFloat(Dailysum) *
              parseFloat(parseFloat(calData?.period) * 30) +
            parseFloat(value);
          setDailyProfit(Dailysum.toFixed(2));
          setTotalProfit(TotalProfitsum.toFixed(2));
        }
      }
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    } else {
      const value = event.target.value;
      const field = event.target.name;
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    }
  };

  if (loading) {
    return;
  }

  return (
    <>
      <section className="container">
        <div className="mining-area">
          <div className="user-form-back">
            <Link to="/user/menu">
              <FontAwesomeIcon icon={faChevronLeft} className="text-start" />
            </Link>
            <h6>Bitcoin Mining</h6>
          </div>
          <div className="order-filter-area">
            <p>
              <FontAwesomeIcon icon={faFilter} /> Filter
            </p>
            <div className="el-select w-full">
              <select
                className="form-select"
                aria-label="Default select example"
                name="filter"
                onChange={handleMiningPage}
              >
                <option selected value="createdAt">
                  Newest
                </option>
                <option value="-createdAt">Older </option>
              </select>
            </div>
          </div>
          <div className="row">
            {/* <SingleItem /> */}
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data._id}
                      handleSubmitData={handleSubmitData}
                      refSubmitDis={refSubmitDis}
                      errorMessage={errorMessage}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <>
                <div className="text-center h-50">
                  <h6> Data not found</h6>
                </div>
              </>
            )}
            <div className="col-10 m-auto">
              {/* paginateLinks */}
              <CustomPagination
                data={MiningaginateData}
                handlePage={handleMiningPage}
              ></CustomPagination>
              {/* paginateLinks */}
            </div>
          </div>

          <div className="row pb-5">
            <div className="calci_bg">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <ul className="calci">
                      <li>
                        <div className="cal_text">
                          <h3>
                            Select Your Plan
                            <select
                              value={calData?._id}
                              onChange={handleSelect}
                              id="Ultra"
                            >
                              <option selected>Select Now</option>
                              {data.map((data, idx) => {
                                return (
                                  <CalculatorData data={data} key={data?._id} />
                                );
                              })}
                            </select>
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="cal_text">
                          <h3>
                            Enter Your Amount
                            <input
                              type="number"
                              className="inpts"
                              name="amount"
                              onChange={handleInputBlur}
                              id="money"
                            />
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-4">
                    <ul className="calci1">
                      <li>
                        <div className="cal_text">
                          <h3>
                            Daily Profit
                            <span id="profitDaily">
                              {typeof DailyProfit !== "string"
                                ? parseFloat(DailyProfit).toFixed(2)
                                : DailyProfit}
                            </span>
                          </h3>
                        </div>
                      </li>
                      <li>
                        <div className="cal_text">
                          <h3>
                            Total Profit
                            <span className="cl1" id="profitTotal">
                              {TotalProfit}
                            </span>
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-4">
                    <div className="text_but text-center">
                      <button
                        type="submit"
                        className="but1 hvr-shutter-in-horizontal"
                      >
                        Calculate Now <i className="ri-play-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <BitcoinMiningHistory updateData={updateData} />
        </div>
      </section>
    </>
  );
};

export default BitcoinMining;
