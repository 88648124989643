import React, { useEffect, useState } from "react";
import "./TradePage.css";
import { Offcanvas } from "react-bootstrap";
import TradeOffcanvas from "./TradeOffcanvas/TradeOffcanvas";
import CryptoCandlestickCharts from "./CryptoCandlestickCharts/CryptoCandlestickCharts";
import CommoditiesCandlestickCharts from "./CommoditiesCandlestickCharts/CommoditiesCandlestickCharts";
import ForexCandlestickCharts from "./ForexCandlestickCharts/ForexCandlestickCharts";
import { useLocation } from "react-router-dom";
const TradePage = () => {
  const location = useLocation();
  const [pageLoading, setPageLoading] = useState(true);
  const [TradeData, setTradeData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location?.state?.Market) {
      setTradeData(location?.state?.Market);
      setPageLoading(false);
    } else {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/market/crypto/currency/view`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const MarketSymbol = data?.data[0]?.Symbol + data?.data[0]?.currency;
          setTradeData({
            MarketData: data?.data[0],
            marketSymbol: MarketSymbol,
            Market: "Crypto",
          });
          setPageLoading(false);
        });
    }
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleTradePage = (data) => {
    setLoading(true);
    setTradeData(data);
    setTimeout(function () {
      setLoading(false);
      handleClose();
    }, 800);
  };

  if (pageLoading || loading) {
    return;
  }
  return (
    <>
      {TradeData?.Market === "Crypto" && (
        <CryptoCandlestickCharts TradeData={TradeData} setShow={setShow} />
      )}
      {TradeData?.Market === "Commodities" && (
        <CommoditiesCandlestickCharts TradeData={TradeData} setShow={setShow} />
      )}
      {TradeData?.Market === "Forex" && (
        <ForexCandlestickCharts TradeData={TradeData} setShow={setShow} />
      )}

      <Offcanvas show={show} backdrop="static" className="trade-offcanvas-area">
        <TradeOffcanvas
          handleClose={handleClose}
          handleTradePage={handleTradePage}
        />
      </Offcanvas>
    </>
  );
};

export default TradePage;
