import React, { useState } from "react";
import "./Market.css";
import Header from "../../Layouts/Header/Header";
import CFDs from "./CFDs/CFDs";
import Crypto from "./Crypto/Crypto";
import Forex from "./Forex/Forex";
import Stocks from "./Stocks/Stocks";
import Commodities from "./Commodities/Commodities";
const Market = () => {
  const [marketShow, setMarketShow] = useState("Forex");

  const handleMarketShow = (market) => {
    setMarketShow(market);
  };
  return (
    <>
      <Header></Header>
      <section className="markets-area">
        <div className="container">
          <div className="markets-menu">
            <div className="d-flex justify-content-between align-items-center">
              <h5   onClick={() => handleMarketShow("Forex")} className={marketShow === "Forex" ? "active" : ""}>Forex</h5>
              {/* <h5 onClick={() => handleMarketShow("Stocks")} className={marketShow === "Stocks" ? "active" : ""}>Stocks</h5> */}
              <h5 onClick={() => handleMarketShow("Commodities")} className={marketShow === "Commodities" ? "active" : ""}>Commodities</h5>
              {/* <h5 onClick={() => handleMarketShow("CFDs")} className={marketShow === "CFDs" ? "active" : ""}>CFDs</h5> */}
              <h5 onClick={() => handleMarketShow("Crypto")} className={marketShow === "Crypto" ? "active" : ""}>Crypto</h5>
            </div>
          </div>
          <div className="markets-items-area">
          {marketShow === "Crypto" && <Crypto  />}
          {marketShow === "Forex" && <Forex   />}
          {marketShow === "Stocks" && <Stocks   />}
          {marketShow === "Commodities" && <Commodities   />}
          {marketShow === "CFDs" && <CFDs   />}
           
          </div>
        </div>
      </section>
    </>
  );
};

export default Market;
