import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
const SingleItem = ({ data, handleSubmitData, refSubmitDis, errorMessage }) => {

  
  const [SingleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if(data?.mining_id){
      fetch(`${process.env.REACT_APP_API}/api/user/mining/view/${data?.mining_id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [data?.mining_id]);


  const rendererTime = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return;
    } else {
      return (
        <>
          <div className="countdown-timer" id="countdown-24">
            <div className="days">{days}d</div>
            <div className="hours">{hours}h</div>
            <div className="minutes">{minutes}m </div>
            <div className="seconds">{seconds}s</div>
          </div>
        </>
      );
    }
  };
  
  if(loading){
    return;
  }

  return (
    <>
      <div className="col-10 m-auto">
        <div className="card-custom">
          <div className="badge-custom2"></div>
          <div className="badge-custom">{SingleData?.percentage}%</div>
          <h5 className="mt-3">
            {SingleData?.instant === 1 ? (
              <>
                <b>
                  <span style={{ color: "gold" }}>Daily for&nbsp;</span>
                </b>
                <span>
                  {`${SingleData?.period}`}&nbsp;{`${SingleData?.period_type}`}
                </span>
              </>
            ) : (
              <>
                <b>
                  <span style={{ color: "gold" }}>AFTER for&nbsp;</span>
                </b>
                <span>
                  {`${SingleData?.period}`}&nbsp;{`${SingleData?.period_type}`}
                </span>
              </>
            )}
          </h5>
          <div className="d-flex flex-row-reverse">
            <div className="text-box">{SingleData?.promotion_name} </div>
          </div>
          <div className="investment-info">
            <p>Min Deposit:&nbsp;${SingleData?.min_amount}</p>
            <p>Max Deposit:&nbsp;${SingleData?.max_amount}</p>
            <p>
              Principal:&nbsp;{SingleData?.principal === 1 ? (
              <span> Returns</span>
              ) : SingleData?.principal === 2 ? (
              <span> Included</span>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="countdown">
            <Countdown date={SingleData?.expired_time} renderer={rendererTime} />
          </div>
          <form onSubmit={handleSubmitData}>
            <label className="mt-2">Amount</label>
            <input type="hidden" name="id" value={SingleData?._id} />
            <input
              name="amount"
              className="form-control"
              placeholder={`Enter amount Eg. ${SingleData?.min_amount}`}
              required=""
              id="amount"
            />
            {errorMessage?.id && errorMessage?.id === SingleData?._id && (
              <>
                {errorMessage?.errors?.amount && (
                  <span className="text-danger pt-2">
                    {errorMessage?.errors?.amount?.msg}
                  </span>
                )}

                {errorMessage?.message && (
                  <span className="text-danger pt-2">
                    {errorMessage?.message}
                  </span>
                )}
              </>
            )}
            <div className="pt-3">
              <button
                type="submit"
                className="but1 hvr-shutter-in-horizontal"
                ref={refSubmitDis}
              >
                Start Mining
                <i className="ri-play-fill"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
