import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
import Countdown from "react-countdown";
const InvestmentPlanHistoryDetails = ({ OderId, handleClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/investment/plan/single/history/view/${OderId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setData(data?.data);
          setLoading(false);
        }
      });
  }, []);

  const c_profit = parseFloat(data?.profit_amount) - parseFloat(data?.amount);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <>&nbsp;completed</>;
    } else {
      // Render a countdown
      return (
        <>
          &nbsp;{days}d&nbsp;{hours}h&nbsp;{minutes}m&nbsp;{seconds}s
        </>
      );
    }
  };

  if (loading) {
    return;
  }
  return (
    <>
      <div className="container">
        <div className="user-history-modal-header">
          <h5>Details</h5>
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} />
        </div>
        <div className="user-history-details-area">
          <div className="user-history-items">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>Name: {data?.promotion_name}</p>
                <p>Invest&nbsp;Amount: ${data?.amount}</p>
                <p>Total&nbsp;Return: ${data?.profit_amount}</p>
                <p>C.Profit: ${c_profit}</p>
                <p>
                  Remaining:
                  <Countdown date={data?.expired_time} renderer={renderer} />
                </p>
                <p>
                  End&nbsp;At: &nbsp;
                  {dateFormat(data?.expired_time, "dd-mm-yyyy h:MM TT")}
                </p>

                <p>
                  Invest&nbsp;Date: &nbsp;
                  {dateFormat(data?.createdAt, "dd-mm-yyyy h:MM TT")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmentPlanHistoryDetails;
