import React from "react";
import dateFormat from "dateformat";
const SingleItems = ({ data }) => {
  return (
    <>
      <div className="records-items">
        <div className="row">
          <div className="col-6">
            <p>
              Quantity:&nbsp;<span>{parseFloat(data?.amount).toFixed(2)}</span>
            </p>
            <p>
              Date:&nbsp;
              <span>
                {dateFormat(data?.createdAt, "yyyy-mm-dd, h:MM:ss TT")}
              </span>
            </p>
          </div>
          <div className="col-6 text-end">
            <p>
              Record:&nbsp;<span>{data?.details}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleItems;
