import React, { useEffect, useState } from "react";
import "./Support.css";
import tel from "./telegram.webp";
import mas from "./massenger.png";
import whatsapp from "./whatsapp.png";
import live from "./live.jpg";
import axios from "axios";
const Support = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/user/social/support/view`)
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("API call error:", err?.message);
      });
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <section className="mobile-contact-section">
        <div className="container">
          <div className="card mb-3">
            <div className="card-content">
              <h5>{data?.dis}</h5>
            </div>
          </div>
          {data?.whatapp_link ? (
            <div className="card mb-3">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="name">
                    <h4>Whatsapp Support</h4>
                  </div>
                  <div className="logo">
                    <img src={whatsapp} alt="" width="40px" height="40px" />
                  </div>
                  <a
                    href={data?.whatapp_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Whatsapp
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {data?.tele_link ? (
            <div className="card mb-3">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="name">
                    <h4>Telegram Support</h4>
                  </div>
                  <div className="logo">
                    <img src={tel} alt="" width="40px" height="40px" />
                  </div>
                  <a
                    href={data?.tele_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Telegram
                  </a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {data?.email && (
            <div className="card mb-3">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="name">
                    <h4>Email Consultation :</h4>
                    <span>{data?.email}</span>
                  </div>
                  <div className="logo">
                    <img src={mas} alt="" width="40px" height="40px" />
                  </div>
                </div>
              </div>
            </div>
          )}
          <a href="/user/live/support" rel="noopener noreferrer">
            <div className="card mb-3">
              <div className="card-content">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="name">
                    <h4>Rubicon</h4>
                  </div>
                  <div className="logo">
                    <img src={live} alt="" width="40px" height="40px" />
                  </div>
                  <a
                    href='/user/live/support'
                    rel="noopener noreferrer"
                  >
                    Live Support
                  </a>
                </div>
              </div>
            </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default Support;
