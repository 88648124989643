import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
const DepositHistoryDetails = ({ OderId, handleClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/api/user/payment/gateways/manual/history/single/view/${OderId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setData(data?.data);
          setLoading(false);
        }
      });
  }, []);

  if (loading) {
    return;
  }
  return (
    <>
      <div className="container">
        <div className="user-history-modal-header">
          <h5>Details</h5>
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} />
        </div>
        <div className="user-history-details-area">
          <div className="user-history-items">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>Network Type: {data?.NetworkType}</p>
                <p>Amount: ${data?.amount}</p>
                <p>Transaction: {data?.transaction}</p>
                <p>
                  Progress:&nbsp;
                  {data?.status === 0 && (
                    <span className="badge bg-warning text-dark">Pending</span>
                  )}
                  {data?.status === 2 && (
                    <span className="badge bg-danger">Reject</span>
                  )}
                  {data?.status === 1 && (
                    <span className="badge bg-primary">Approved</span>
                  )}
                </p>
                <p>Date: &nbsp;{dateFormat(data?.createdAt, "dd-mm-yyyy h:MM TT")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositHistoryDetails;
