import React from 'react';

const Overview = () => {
    return (
        <>
         <div className="user-assets-overview">
              <div className="user-assets-overview-items">
                <div className="row">
                  <div className="col-6">
                    <p>Curriency</p>
                    <span>Assets</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>0.00 USDT</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
              <div className="user-assets-overview-items">
                <div className="row">
                  <div className="col-6">
                    <p>Commodities</p>
                    <span>Assets</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>0.00 USDT</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
              <div className="user-assets-overview-items">
                <div className="row">
                  <div className="col-6">
                    <p>CFDs</p>
                    <span>Assets</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>0.00 USDT</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
              <div className="user-assets-overview-items">
                <div className="row">
                  <div className="col-6">
                    <p>Forex</p>
                    <span>Assets</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>0.00 USDT</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
              <div className="user-assets-overview-items">
                <div className="row">
                  <div className="col-6">
                    <p>Stock</p>
                    <span>Assets</span>
                  </div>
                  <div className="col-6 text-end">
                    <p>0.00 USDT</p>
                    <span>0.00 USD</span>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
};

export default Overview;