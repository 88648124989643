import React, { useContext, useEffect, useRef, useState } from "react";
import "./Loan.css";
import SingleItem from "./SingleItem";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { faChevronLeft, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LoanHistory from "./LoanHistory/LoanHistory";
import CustomPagination from "../../../../CustomPagination/CustomPagination";
const Loan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [MiningaginateData, setMiningaginateData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/user/loan/package/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setData(data?.data);
        setMiningaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState('');
  
  const handleMiningPage = (event) => {
    try {
      if(event?.target?.value){
        setFilter(event?.target?.value)
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      if(event?.target?.value){
      
      axios
        .get(
          `${process.env.REACT_APP_API}/api/user/loan/package/filter/view?&page=1&filter=${event?.target?.value}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setMiningaginateData(response?.data);
        })
        .catch((error) => {});

      }else{
        axios
          .get(
            `${process.env.REACT_APP_API}/api/user/loan/package/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setMiningaginateData(response?.data);
          })
          .catch((error) => {});

      }

    } catch (error) {
      console.log(error);
    }
  };

  // Handle submit form

  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);

  const navigate = useNavigate();

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_API}/api/user/loan/amount/check`,
          { user_id: authUser?._id, ...InpuData },
          config
        )
        .then((data) => {
          const packageId = data?.data?.data?._id;
          if (packageId) {
            const Location = `/loan/confirm/${data?.data?.amount}/${packageId}`;
            navigate(Location, { replace: true });
            refSubmitDis.current.removeAttribute("disabled");
            event.target.reset();
          }
        })
        .catch((error) => {
          setErrorMessage({ id: InpuData?.id, ...error?.response?.data });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  if(loading){
    return;
  }
  return (
    <>
      <section className="user-loan-area">
        <div className="container">
          <div className="user-form-back">
            <Link to="/user/menu">
              {" "}
              <FontAwesomeIcon icon={faChevronLeft} className="text-start" />
            </Link>
            <h6>Loan</h6>
          </div>
        <div className="order-filter-area">
          <p><FontAwesomeIcon icon={faFilter}  /> Filter</p>
          <div className="el-select w-full">
            <select
              className="form-select"
              aria-label="Default select example"
              name="filter"
              onChange={handleMiningPage}
            >
              <option selected value="createdAt">
              Newest 
              </option>
              <option value="-createdAt">Older </option>
              
            </select>
          </div>

        </div>
          <div className="row">
            {data?.length !== 0 ? (
              data.map((data, index) => {
                if (data) {
                  return (
                    <SingleItem
                      data={data}
                      index={index}
                      key={data._id}
                      handleSubmitData={handleSubmitData}
                      refSubmitDis={refSubmitDis}
                      errorMessage={errorMessage}
                    ></SingleItem>
                  );
                }
              })
            ) : (
              <>
                <div className="text-center h-50">
                  <h6> Data not found</h6>
                </div>
              </>
            )}

<div className="col-10 m-auto">
                 {/* paginateLinks */}
            <CustomPagination
              data={MiningaginateData}
              handlePage={handleMiningPage}
            ></CustomPagination>
            {/* paginateLinks */}
            </div>
          </div>

          <LoanHistory />
        </div>
      </section>
    </>
  );
};

export default Loan;
