import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
import axios from "axios";
import { toast } from "react-toastify";
const OrderDetailsModal = ({ OderId, handleClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(OderId){
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/single/view/${OderId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.success) {
            setData(data?.data);
            setLoading(false);
          }
        });
    }
  }, [OderId]);

  //--------- Set Loss -------------//

  const [LossValue, setLossVale] = useState(0);

  const handleSetLoss = (e) => {
    if (e === "-") {
      const minusValue = parseFloat(parseFloat(LossValue) - 1);
      setLossVale(minusValue);
    } else if (e === "+") {
      const plusValue = parseFloat(parseFloat(LossValue) + 1);
      setLossVale(plusValue);
    } else {
      const value = e.target.value;
      setLossVale(parseFloat(value));
    }
  };

  //--------- Take Profit  -------------//

  const [takeProfitValue, setTakeProfitValue] = useState(0);

  const handleTakeProfit = (e) => {
    if (e === "-") {
      const minusValue = parseFloat(parseFloat(takeProfitValue) - 1);
      setTakeProfitValue(minusValue);
    } else if (e === "+") {
      const plusValue = parseFloat(parseFloat(takeProfitValue) + 1);
      setTakeProfitValue(plusValue);
    } else {
      const value = e.target.value;
      setTakeProfitValue(parseFloat(value));
    }
  };

  const refSubmitDis = useRef();

  const [errorMessage, setErrorMessage] = useState([]);

  const updateOrderTPSL = () => {
    const storeData = {
      set_loss: LossValue,
      take_profit: takeProfitValue,
    };
    setErrorMessage();
    refSubmitDis.current.setAttribute("disabled", true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API}/api/user/trade/log/update/${data?._id}`,
        storeData,
        config
      )
      .then((data) => {
        if (data?.data?.success === true) {
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
          handleClose();
        } else {
          toast.error(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        }
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.errors);
        if (
          error?.response?.data?.success === false &&
          error?.response?.data?.message
        ) {
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        refSubmitDis.current.removeAttribute("disabled");
      });
  };

  //   handle close

  const HandleClose = (id) => {
    if (id) {
      fetch(
        `${process.env.REACT_APP_API}/api/user/trade/log/close/possition/${id}`,
        {
          method: "PUT",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.success) {
            toast.error(`${data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            handleClose();
          }
        });
    }
  };

  if (loading) {
    return;
  }
  return (
    <>
      <div className="container">
        <div className="user-history-modal-header">
          <h5>Order Details</h5>
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} />
        </div>
        <div className="user-history-details-area">
          <h2>{data?.pairs}</h2>
          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="orders-item-details">
              <p>
                {data?.lower_unit_price}
                {data?.status && !data?.pending_order_status && (
                  <>
                    -&gt;
                    <span>{data?.current_price}</span>
                  </>
                )}
              </p>
            </div>
            <div
              className={
                data?.direction === "Buy"
                  ? "orders-item-up-value"
                  : "orders-item-dwon-value"
              }
            >
              <h6>
                <p>{data?.direction === "Buy" ? "Buy" : "Sell"}</p>
                <span>{data?.lots} Lots</span>
              </h6>
            </div>
          </div>
          <div className="user-history-items">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>Set loss: {data?.set_loss}</p>
                <p>Take profit: {data?.take_profit}</p>
                <p>Margin: {data?.margin}</p>
                <p>Handling fee: 0</p>
                <p>Orders ID {data?.transaction}</p>
                <p> {dateFormat(data?.createdAt, "dd-mm-yyyy h:MM TT")}</p>
              </div>
              <div className="orders-history-item">
                <div
                  className={
                    data?.direction === "Buy"
                      ? "orders-item-up-value"
                      : "orders-item-dwon-value"
                  }
                >
                  <h5
                    className={
                      parseFloat(data?.profit) < 0 ? "text-danger-1" : ""
                    }
                  >
                    {parseFloat(data?.profit).toFixed(2)}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="user-trade-page-area pb-5">
            <div className="d-flex align-items-center justify-content-between">
              <div className="user-trade-page py-0">
                <div className=" rounded-10  box-border ">
                  <div className="el-select w-full">
                    <div className="text-14 trade-dis-btn">Set Loss</div>
                    <div className="trade-quantity">
                      <button
                        className="btn "
                        id="minusBtn"
                        onClick={() => handleSetLoss("-")}
                        disabled={LossValue <= 0 ? true : false}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        id="quantityInput"
                        className="form-control "
                        value={LossValue}
                        onChange={handleSetLoss}
                      />

                      <button
                        className="btn "
                        id="plusBtn"
                        onClick={() => handleSetLoss("+")}
                      >
                        +
                      </button>
                    </div>
                    {errorMessage?.set_loss && (
                      <span className="text-danger pt-2">
                        {errorMessage?.set_loss?.msg}
                      </span>
                    )}
                  </div>
                  <div className="el-select w-full">
                    <div className="text-14  trade-dis-btn">Take Profit</div>
                    <div className="trade-quantity">
                      <button
                        className="btn "
                        id="minusBtn"
                        onClick={() => handleTakeProfit("-")}
                        disabled={takeProfitValue <= 0 ? true : false}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        id="quantityInput"
                        className="form-control "
                        value={takeProfitValue}
                        onChange={handleTakeProfit}
                      />

                      <button
                        className="btn "
                        id="plusBtn"
                        onClick={() => handleTakeProfit("+")}
                      >
                        +
                      </button>
                    </div>
                    {errorMessage?.take_profit && (
                      <span className="text-danger pt-2">
                        {errorMessage?.take_profit?.msg}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="order-history-update-btn"
                aria-disabled="false"
                type="submit"
                onClick={updateOrderTPSL}
                ref={refSubmitDis}
              >
                Set
              </button>
            </div>
          </div>

          <div className="d-grid gap-2 user-trade-close-btn">
            <p
              className="btn btn-primary round-20"
              onClick={() => HandleClose(data?._id)}
            >
              Close Posttion
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetailsModal;
