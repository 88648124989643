import React from "react";
import dateFormat from "dateformat";
const SingleItem = ({ data, HandleModalShow, index }) => {
  return (
    <>
      <div
        className="mobile-latest-history mobile-history-item"
        onClick={() => HandleModalShow(data?._id)}
      >
        <div className="mobile-history-item-left">
          <div className="mobile-history-item-sn">
            <p>S.N</p>
            <span>
              #
              {index+1}
            </span>
          </div>
          <div>
            <p>{data?.NetworkType}</p>
            <span>{data?.Transaction}</span>
          </div>
        </div>

        <div className="mobile-history-item-right">
          <p>${data?.AmountWithVat}</p>
          <span>
            {dateFormat(data?.createdAt, "mmm,")}&nbsp;
            {dateFormat(data?.createdAt, "dS")}&nbsp;
            {dateFormat(data?.createdAt, "yyyy")}
          </span>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
