import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
const SingleItem = ({ data, handleTradePage }) => {
  const [liveData, setLiveData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [liveData]);

  const fetchData = async () => {
    fetch(
      `https://api.polygon.io/v2/snapshot/locale/global/markets/forex/tickers/C:${data?.symbol}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.ticker) {
          setLiveData(data?.ticker);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  if (loading) {
    return;
  }
  return (
    <>
      <div
        className="markets-item"
        onClick={() =>
          handleTradePage({
            MarketData: data,
            marketSymbol: data?.symbol,
            Market: "Commodities",
          })
        }
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="markets-item-name">
            <LazyLoadImage
              alt=" img"
              src={`${process.env.REACT_APP_API}/${data?.image}`}
            />
            <h6>{data?.symbol}</h6>
          </div>
          <div className="markets-value">
            {liveData?.lastQuote ? (
              <>
                <p>
                  {liveData &&
                    parseFloat(liveData?.lastQuote?.a) +
                      parseFloat(data?.price)}
                </p>
                <span
                  className={
                    liveData?.todaysChangePerc < 0 ? "text-danger" : ""
                  }
                >
                  {parseFloat(liveData?.todaysChangePerc).toFixed(5)}%
                  {liveData?.todaysChangePerc < 0 ? (
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="text-danger"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faCaretUp} />
                  )}
                </span>
              </>
            ) : (
              <>
                <h6 className="closed-market-btn">Closed</h6>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
