import React from "react";
import "./Header.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "./../../../../../Logo.jpg";
import language from "./language.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="user-header-area">
            <div className="d-flex justify-content-between align-items-center">
              {/* <div className="user-language">
                <LazyLoadImage alt="language img" src={language} />
              </div> */}
              <div className="user-logo">
                <LazyLoadImage alt="logo img" src={logo} />
              </div>
              <div className="user-search">
                <Link to="/user/support">
                <FontAwesomeIcon icon={faHeadset} />
                 </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
