import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Crypto from "./Market/Crypto/Crypto";
import Forex from "./Market/Forex/Forex";
import Stocks from "./Market/Stocks/Stocks";
import Commodities from "./Market/Commodities/Commodities";
import CFDs from "./Market/CFDs/CFDs";

const TradeOffcanvas = ({ handleClose, handleTradePage }) => {
  const [marketShow, setMarketShow] = useState("Crypto");

  const handleMarketShow = (market) => {
    setMarketShow(market);
  };
  return (
    <>
      <div className="container">
        <div className="offcanvas-close-btn">
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} />
        </div>
      </div>
      <section className="markets-area">
        <div className="container">
          <div className="markets-menu">
            <div className="d-flex justify-content-between align-items-center">
              <h5
                onClick={() => handleMarketShow("Forex")}
                className={marketShow === "Forex" ? "active" : ""}
              >
                Forex
              </h5>
              {/* <h5
                onClick={() => handleMarketShow("Stocks")}
                className={marketShow === "Stocks" ? "active" : ""}
              >
                Stocks
              </h5> */}
              <h5
                onClick={() => handleMarketShow("Commodities")}
                className={marketShow === "Commodities" ? "active" : ""}
              >
                Commodities
              </h5>
              {/* <h5
                onClick={() => handleMarketShow("CFDs")}
                className={marketShow === "CFDs" ? "active" : ""}
              >
                CFDs
              </h5> */}
              <h5
                onClick={() => handleMarketShow("Crypto")}
                className={marketShow === "Crypto" ? "active" : ""}
              >
                Crypto
              </h5>
            </div>
          </div>
          <div className="trade-market-item markets-items-area">
            {marketShow === "Crypto" && <Crypto handleTradePage={handleTradePage} />}
            {marketShow === "Forex" && <Forex handleTradePage={handleTradePage} />}
            {/* {marketShow === "Stocks" && <Stocks handleTradePage={handleTradePage} />} */}
            {marketShow === "Commodities" && <Commodities handleTradePage={handleTradePage} />}
            {/* {marketShow === "CFDs" && <CFDs handleTradePage={handleTradePage} />} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default TradeOffcanvas;
