import React from "react";
import dateFormat from "dateformat";
import Countdown from "react-countdown";
const SingleItem = ({ data, HandleModalShow, index }) => {
      // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>completed</span>;
    } else {
      // Render a countdown
      return (
        <>
          <span>
            {days}d&nbsp;{hours}h&nbsp;{minutes}m&nbsp;{seconds}s
          </span>
        </>
      );
    }
  };
  return (
    <>
      <div
        className="mobile-latest-history  mobile-history-item"
        onClick={() => HandleModalShow(data?._id)}
      >
        <div className="mobile-history-item-left">
          <div className="mobile-history-item-sn">
            <p>S.N</p>
            <span>
              #
              {index+1}
            </span>
          </div>
          <div>
            <p>{data?.promotion_name}</p>
            <Countdown date={data?.expired_time} renderer={renderer} />
          </div>
        </div>

        <div className="mobile-history-item-right">
          <p>${data?.amount}</p>
          <span>
            {dateFormat(data?.createdAt, "mmm,")}&nbsp;
            {dateFormat(data?.createdAt, "dS")}&nbsp;
            {dateFormat(data?.createdAt, "yyyy")}
          </span>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
